import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
// Hooks & Querys
import { useSanityPath } from '../../hooks';

export const PageLink = ({ page, linkText, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <Link to={path} {...rest}>
      {linkText}
    </Link>
  );
};

export const PageButton = ({ page, linkText, ...rest }) => {
  const path = useSanityPath(page);

  return (
    <Link
      to={path}
      {...rest}
      className="bg-knode-blue text-white px-12 py-4 rounded-full font-bold text-base"
    >
      {linkText}
    </Link>
  );
};

export const PathLink = ({ path, linkText, ...rest }) => {
  return (
    <Link to={`/${path}`} {...rest}>
      {linkText}
    </Link>
  );
};

export const AnchorPageLink = ({ page, linkText, anchor, ...rest }) => {
  const path = useSanityPath(page);
  return <AnchorLink to={`${path}#${anchor && anchor.current}`} title={linkText} {...rest} />;
};
