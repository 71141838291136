import React from 'react';

import { PageLink, PathLink, AnchorPageLink } from '../ui';

export const NavLink = ({ _type, ...rest }) => {
  const LinkComponent = () => {
    switch (_type) {
      case 'pageLink':
        return <PageLink {...rest} />;
      case 'pathLink':
        return <PathLink {...rest} />;
      case 'pageAnchorLink':
        return <AnchorPageLink {...rest} />;
      default:
        return null;
    }
  };
  return <LinkComponent />;
};
