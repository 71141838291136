export const usePadBottom = (size) => {
  switch (size) {
    case "xs":
      return "pb-[15px] sm:pb-[25px] xl:pb-[50px]";
    case "small":
      return "pb-[15px] sm:pb-[25px] xl:pb-[50px]";
    case "medium":
      return "pb-[40px] sm:pb-[60px] xl:pb-[80px]";
    case "large":
      return "pb-[60px] md:pb-[75px] xl:pb-[100px]";
    default:
      return null;
  }
};
