import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import { useScrollFreeze } from "../../hooks";
import { useSiteConfig } from "../../GraphQl/useSiteConfig";

import { NavLink } from "./navLink";

const navWrapperVars = {
  closed: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  open: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const navAniVars = {
  closed: {
    y: 20,
    opacity: 0,
  },
  open: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const NavInner = ({ onClose }) => {
  useScrollFreeze();
  const { mainNav } = useSiteConfig();

  return (
    <motion.div
      variants={navWrapperVars}
      initial="closed"
      animate="open"
      exit="closed"
      className="fixed bg-knode-navy h-screen w-screen top-0 left-0 z-50 flex items-center px-gutter"
    >
      <motion.nav
        className="grid gap-y-10 justify-center items-center w-full"
        variants={navAniVars}
      >
        {mainNav.map((item) => (
          <div onClick={onClose} className="text-center">
            <NavLink {...item} className="font-medium text-2xl md:text-3xl" />
          </div>
        ))}
        <a
          href="https://my.knode.co.nz/login"
          className="bg-knode-aqua text-white px-12 py-3 rounded-full font-bold text-base text-center"
          targer="_blank"
        >
          Login
        </a>
      </motion.nav>
    </motion.div>
  );
};

export const MobileNav = ({ isOpen, onClose }) => {
  return (
    <AnimatePresence>
      {isOpen && <NavInner onClose={onClose} />}
    </AnimatePresence>
  );
};
