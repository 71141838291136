import React, { useState, useEffect } from "react";
import Headroom from "react-headroom";
import { Link } from "gatsby";
import { useSiteConfig } from "../../GraphQl/useSiteConfig";

import { useToggle } from "../../hooks";

import { NavLink } from "./navLink";
import { MenuToggle } from "./menuToggle";
import { MobileNav } from "./mobileNav";

import Logo from "../../svg/logo.svg";

export const Header = () => {
  const { mainNav } = useSiteConfig();
  const [navOpen, setNavOpen, toggleNavOpen] = useToggle(false);
  const [headerPinned, setHeaderPinned] = useState(false);

  useEffect(() => {
    if (headerPinned) {
      document.documentElement.classList.add("header-pinned");
    } else {
      document.documentElement.classList.remove("header-pinned");
    }
  }, [headerPinned]);
  return (
    <>
      <Headroom
        style={{ zIndex: "1000" }}
        onPin={() => setHeaderPinned(true)}
        onUnpin={() => setHeaderPinned(false)}
        onUnfix={() => setHeaderPinned(false)}
      >
        <header
          className={`w-full flex justify-between items-center z-2000 px-gutter lg:px-10 transition-all ${
            headerPinned ? "backdrop-blur-lg bg-white/20 py-4" : "py-10"
          }`}
        >
          <Link to="/" className="w-[153px]">
            <Logo className="w-full" />
          </Link>
          <nav className="gap-x-8 lg:gap-x-14 items-center hidden lg:grid lg:grid-flow-col">
            {mainNav.map((item, i) => (
              <NavLink
                key={i}
                {...item}
                activeClassName="text-knode-blue"
                style={{ fontWeight: "500" }}
                className="blockH7 hover:text-knode-blue transition-all ease-in-out duration-300"
              />
            ))}
            <a
              href="https://my.knode.co.nz/login"
              className=" bg-knode-aqua hover:bg-knode-aqua/80 text-white px-12 py-3 rounded-full  blockH8 hover:scale-105 transition-all ease-in-out duration-300"
              target="_blank"
              rel="noreferrer"
            >
              Login
            </a>
          </nav>
          <div className="lg:hidden translate-y-2 w-10 h-10">
            <MenuToggle
              isOpen={navOpen}
              onClick={toggleNavOpen}
              className="lg:hidden translate-y-2 w-full h-full"
            />
          </div>
        </header>
      </Headroom>
      <MobileNav isOpen={navOpen} onClose={() => setNavOpen(false)} />
    </>
  );
};
