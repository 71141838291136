import React from "react";
import { motion } from "framer-motion";

// Animation
const top = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  opened: {
    rotate: 45,
    translateY: 2,
  },
};

const center = {
  closed: {
    opacity: 1,
  },
  opened: {
    opacity: 0,
  },
};

const bottom = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  opened: {
    rotate: -45,
    translateY: -2,
  },
};

export const MenuToggle = ({
  isOpen,
  reverseHeader,
  width = 32,
  height = 21,
  ...props
}) => {
  const variant = isOpen ? "opened" : "closed";
  const unitHeight = 5.6;
  const unitWidth = (unitHeight * width) / height;
  return (
    <div {...props}>
      <svg
        viewBox={`0 0 ${unitWidth} ${unitHeight}`}
        overflow="visible"
        preserveAspectRatio="none"
        width={width}
        height={height}
        strokeWidth="2"
        className="stroke-current"
        // stroke="#10d4ff"
      >
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="0"
          y2="0"
          variants={top}
          animate={variant}
          initial="closed"
          vectorEffect="non-scaling-stroke"
          stroke="#10d4ff"
        />
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="2"
          y2="2"
          variants={center}
          animate={variant}
          initial="closed"
          vectorEffect="non-scaling-stroke"
          stroke="#10d4ff"
        />
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="4"
          y2="4"
          variants={bottom}
          animate={variant}
          initial="closed"
          vectorEffect="non-scaling-stroke"
          stroke="#10d4ff"
        />
      </svg>
    </div>
  );
};
