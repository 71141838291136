export const usePadTop = (size) => {
  switch (size) {
    case "xs":
      return "pt-[15px] sm:pt-[25px] xl:pt-[50px]";
    case "small":
      return "pt-[15px] sm:pt-[25px] xl:pt-[50px]";
    case "medium":
      return "pt-[40px] sm:pt-[60px] xl:pt-[80px]";
    case "large":
      return "pt-[60px] md:pt-[75px] xl:pt-[100px]";
    default:
      return null;
  }
};
